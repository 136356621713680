import Vue from 'vue'
import VueRouter from 'vue-router'
import { userService } from '../services/user.services'

Vue.use(VueRouter)

const routes = [
    {
        path:'/main/',
        name:'main',
        component: () => import('../app/Main'),
        children:[
            {
              path: '/home',
              name: 'home',
              meta: {
                title: 'Azemi'
              },
              component: () => import('../screens/home/ScreenHome.vue'),
            },            {
              path: '/ayuda/tutoriales',
              name: 'ayuda/tutoriales',
              meta: {
                title: 'Ayuda / Tutoriales'
              },
              component: () => import('../screens/ayuda/Tutoriales.vue')
            },
            {
                path: '/sucursal/agregar',
                name: 'sucursal/agregar',
                meta: {
                  title: 'Configuración / Sucursales / Agregar'
                },
                component: () => import('../screens/sucursal/Agregar.vue')
            },
            {
                path: '/sucursal/editar/:sucursal',
                name: 'sucursal/editar',
                meta: {
                  title: 'Configuración / Sucursales / Editar'
                },
                component: () => import('../screens/sucursal/Editar.vue')
            },
            {
              path: '/sucursal/usuarios/:sucursal',
              name: 'sucursal/usuarios',
              meta: {
                title: 'Configuración / Sucursales / Usuarios'
              },
              component: () => import('../screens/sucursal/Usuarios.vue')
            },
            {
              path: '/sucursal/trabajadores/:sucursal',
              name: 'sucursal/trabajadores',
              meta: {
                title: 'Configuración / Sucursales / Trabajadores'
              },
              component: () => import('../screens/sucursal/Trabajadores.vue')
            },
            {
              path: '/sucursal/vehiculos/:sucursal',
              name: 'sucursal/vehiculos',
              meta: {
                title: 'Configuración / Sucursales / Vehiculos'
              },
              component: () => import('../screens/sucursal/Vehiculos.vue')
            },
            {
              path: '/sucursal/empresas/:sucursal',
              name: 'sucursal/empresas',
              meta: {
                title: 'Configuración / Sucursales / Empresas'
              },
              component: () => import('../screens/sucursal/Empresas.vue')
            },
            {
                path: '/sucursal/configurar',
                name: 'sucursal/configurar',
                meta: {
                  title: 'Configuración / Sucursales'
                },
                component: () => import('../screens/sucursal/Configurar.vue')
            },
            {
                path: '/reporte/documentos_resumen',
                name: 'reporte/documentos_resumen',
                meta: {
                  title: 'Reportes / Resumen documentos'
                },
                component: () => import('../screens/reporte/DocumentosResumen.vue')
            },
            {
                path: '/reporte/documentos_empresas',
                name: 'reporte/documentos_empresas',
                meta: {
                  title: 'Reportes / Resumen documentos empresas'
                },
                component: () => import('../screens/reporte/DocumentosEmpresas.vue')
            },
            {
                path: '/reporte/documentos_trabajadores',
                name: 'reporte/documentos_trabajadores',
                meta: {
                  title: 'Reportes / Resumen documentos trabajadores'
                },
                component: () => import('../screens/reporte/DocumentosTrabajadores.vue')
            },
            {
                path: '/reporte/documentos_vehiculos',
                name: 'reporte/documentos_vehiculos',
                meta: {
                  title: 'Reportes / Resumen documentos vehiculos'
                },
                component: () => import('../screens/reporte/DocumentosVehiculos.vue')
            },
            {
                path: '/reporte/resumen_global',
                name: 'reporte/resumen_global',
                meta: {
                  title: 'Reportes / Resumen global'
                },
                component: () => import('../screens/reporte/ResumenGlobal.vue')
            },
            {
                path: '/cliente/cliente',
                name: 'cliente/cliente',
                meta: {
                  title: 'Clientes'
                },
                component: () => import('../screens/cliente/Cliente.vue')
            },
            {
                path: '/cliente/editar/:id_cliente',
                name: 'cliente/editar',
                meta: {
                  title: 'Editar cliente'
                },
                component: () => import('../screens/cliente/Editar.vue')
            },
            {
                path: '/cliente/agregar',
                name: 'cliente/agregar',
                meta: {
                  title: 'Agregar cliente'
                },
                component: () => import('../screens/cliente/Agregar.vue')
            },
            {
                path: '/trabajador/trabajadores',
                name: 'trabajador/trabajadores',
                meta: {
                  title: 'Trabajadores'
                },
                component: () => import('../screens/trabajador/Trabajadores.vue')
            },
            {
                path: '/trabajador/editar/:trabajador',
                name: 'trabajador/editar',
                meta: {
                  title: 'Trabajador / Editar'
                },
                props: true,
                component: () => import('../screens/trabajador/Editar.vue')
            },
            {
                path: '/trabajador/agregar',
                name: 'trabajador/agregar',
                meta: {
                  title: 'Trabajador / Agregar'
                },
                component: () => import('../screens/trabajador/Agregar.vue')
            },
            {
                path: '/trabajador/documento/documentos',
                name: 'trabajador/documento/documentos',
                meta: {
                  title: 'Trabajadores / Documentos'
                },
                component: () => import('../screens/trabajador/documento/Documentos.vue')
            },
            {
                path: '/trabajador/documento/documento_por_trabajador',
                name: 'trabajador/documento/documento_por_trabajador',
                meta: {
                  title: 'Trabajadores / Documentos por trabajador'
                },
                component: () => import('../screens/trabajador/documento/DocumentoPorTrabajador.vue')
            },
            {
                path: '/trabajador/documento/trabajador/:id_trabajador',
                name: 'trabajador/documento/trabajador',
                meta: {
                  title: 'Documentos trabajador'
                },
                component: () => import('../screens/trabajador/documento/Trabajador.vue')
            },
            {
                path: '/trabajador/documento/historicos',
                name: 'trabajador/documento/historicos',
                meta: {
                  title: 'Trabajador / Documentos historicos'
                },
                component: () => import('../screens/trabajador/documento/Historico.vue')
            },
            {
                path: '/trabajador/documento/configurar*',
                name: 'trabajador/documento/configurar',
                meta: {
                  title: 'Configuración / Documentos trabajador'
                },
                component: () => import('../screens/trabajador/documento/Configurar.vue')
            },
            {
                path: '/trabajador/documento/categoria/agregar/:tipo_trabajador',
                name: 'trabajador/documento/categoria/agregar',
                meta: {
                    title: 'Configuración / Documentos trabajador / Agregar categoria '
                },
                component: () => import('../screens/trabajador/documento/categoria/AgregarCategoria.vue')
            },
            {
                path: '/trabajador/documento/categoria/editar/:categoria',
                name: 'trabajador/documento/categoria/editar',
                meta: {
                    title: 'Configuración / Documentos trabajador / Editar categoria '
                },
                component: () => import('../screens/trabajador/documento/categoria/EditarCategoria.vue')
            },
            {
                path: '/trabajador/documento/tipo_documento/agregar/:categoria',
                name: 'trabajador/documento/tipo_documento/agregar',
                meta: {
                    title: 'Configuración / Documentos trabajador / Agregar documento '
                },
                component: () => import('../screens/trabajador/documento/tipo_documento/AgregarTipoDocumento.vue')
            },
            {
                path: '/trabajador/documento/tipo_documento/editar/:tipo',
                name: 'trabajador/documento/tipo_documento/editar',
                meta: {
                    title: 'Configuración / Documentos trabajador / Editar documento '
                },
                component: () => import('../screens/trabajador/documento/tipo_documento/EditarTipoDocumento.vue')
            },
            {
                path: '/trabajador/documento/descarga_masiva',
                name: 'trabajador/documento/descarga_masiva',
                meta: {
                  title: 'Descarga de documentos / Trabajadores'
                },
                component: () => import('../screens/trabajador/documento/DescargaMasiva.vue')
            },
            {
                path: '/trabajador/configurar',
                name: 'trabajador/configurar',
                meta: {
                  title: 'Configuración / Trabajadores'
                },
                component: () => import('../screens/trabajador/configurar/Configurar.vue')
            },
            {
                path: '/trabajador/configurar/campo',
                name: 'trabajador/configurar/campo',
                meta: {
                  title: 'Configuración / Trabajadores / Campos'
                },
                component: () => import('../screens/trabajador/configurar/campo/Campos.vue')
            },
            {
                path: '/trabajador/configurar/tipo',
                name: 'trabajador/configurar/tipo',
                meta: {
                  title: 'Configuración / Trabajadores / Tipo de trabajador'
                },
                component: () => import('../screens/trabajador/configurar/tipo/Tipos.vue')
            },
            {
                path: '/trabajador/configurar/tipo/agregar',
                name: 'trabajador/configurar/tipo/agregar',
                meta: {
                  title: 'Configuración / Trabajadores / Tipo de trabajador / Agregar'
                },
                component: () => import('../screens/trabajador/configurar/tipo/Agregar.vue')
            },
            {
                path: '/trabajador/configurar/tipo/editar/:tipo_trabajador',
                name: 'trabajador/configurar/tipo/editar',
                meta: {
                  title: 'Configuración / Trabajadores / Tipo de trabajador / Editar'
                },
                props: true,
                component: () => import('../screens/trabajador/configurar/tipo/Editar.vue')
            },
            {
                path: '/trabajador/documento/vencimientos',
                name: 'trabajador/documento/vencimientos',
                meta: {
                  title: 'Reportes / Trabajadores / Vencimiento documentos'
                },
                component: () => import('../screens/trabajador/documento/Vencimientos.vue')
            },
            {
                path: '/empresa/empresas',
                name: 'empresa/empresas',
                meta: {
                  title: 'Empresas'
                },
                component: () => import('../screens/empresa/Empresas.vue')
            },
            {
                path: '/empresa/editar/:empresa',
                name: 'empresa/editar',
                meta: {
                  title: 'Empresa / Editar'
                },
                props: true,
                component: () => import('../screens/empresa/Editar.vue')
            },
            {
                path: '/empresa/agregar',
                name: 'empresa/agregar',
                meta: {
                  title: 'Empresa / Agregar'
                },
                component: () => import('../screens/empresa/Agregar.vue')
            },
            {
                path: '/empresa/documento/documentos',
                name: 'empresa/documento/documentos',
                meta: {
                  title: 'Empresa / Documentos'
                },
                component: () => import('../screens/empresa/documento/Documentos.vue')
            },
            {
                path: '/empresa/documento/historicos',
                name: 'empresa/documento/historicos',
                meta: {
                  title: 'Empresa / Documentos historicos'
                },
                component: () => import('../screens/empresa/documento/Historico.vue')
            },
            {
                path: '/empresa/documento/configurar',
                name: 'empresa/documento/configurar',
                meta: {
                    title: 'Configuración / Documentos empresa'
                },
                component: () => import('../screens/empresa/documento/Configurar.vue')
            },
            {
                path: '/empresa/documento/categoria/agregar',
                name: 'empresa/documento/categoria/agregar',
                meta: {
                    title: 'Configuración / Documentos empresa / Agregar categoria'
                },
                component: () => import('../screens/empresa/documento/categoria/AgregarCategoria.vue')
            },
            {
                path: '/empresa/documento/categoria/editar/:categoria',
                name: 'empresa/documento/categoria/editar',
                meta: {
                    title: 'Configuración / Documentos empresa / Editar categoria'
                },
                component: () => import('../screens/empresa/documento/categoria/EditarCategoria.vue')
            },
            {
                path: '/empresa/documento/tipo_documento/agregar/:categoria',
                name: 'empresa/documento/tipo_documento/agregar',
                meta: {
                    title: 'Configuración / Documentos empresa / Agregar documento'
                },
                component: () => import('../screens/empresa/documento/tipo_documento/AgregarTipoDocumento.vue')
            },
            {
                path: '/empresa/documento/tipo_documento/editar/:tipo',
                name: 'empresa/documento/tipo_documento/editar',
                meta: {
                    title: 'Configuración / Documentos empresa / Editar documento'
                },
                component: () => import('../screens/empresa/documento/tipo_documento/EditarTipoDocumento.vue')
            },
            {
                path: '/empresa/documento/descarga_masiva',
                name: 'empresa/documento/descarga_masiva',
                meta: {
                  title: 'Descarga de documentos / Empresas'
                },
                component: () => import('../screens/empresa/documento/DescargaMasiva.vue')
            },
            {
                path: '/empresa/documento/vencimientos',
                name: 'empresa/documento/vencimientos',
                meta: {
                    title: 'Reportes / Empresas / Vencimiento documentos'
                },
                component: () => import('../screens/empresa/documento/Vencimientos.vue')
            },
            {
                path: '/usuario/agregar/:empresa',
                name: 'usuario/agregar',
                meta: {
                  title: 'Usuario / Agregar'
                },
                props: true,
                component: () => import('../screens/usuario/Agregar.vue')
            },
            {
                path: '/usuario/editar/:usuario',
                name: 'usuario/editar',
                meta: {
                  title: 'Usuario / Editar'
                },
                props: true,
                component: () => import('../screens/usuario/Editar.vue')
            },
            {
                path: '/usuario/usuarios_empresa/:empresa',
                name: 'usuario/usuarios_empresa',
                meta: {
                  title: 'Empresa / Usuarios'
                },
                props: true,
                component: () => import('../screens/usuario/UsuariosEmpresa.vue')
            },
            {
                path: '/usuario/usuarios_cliente',
                name: 'usuario/usuarios_cliente',
                meta: {
                  title: 'Cliente / Usuarios'
                },
                props: true,
                component: () => import('../screens/usuario/UsuariosCliente.vue')
            },
            {
                path: '/sistema/configuracion',
                name: 'sistema/configuracion',
                meta: {
                    title: 'Configuración / Sistema'
                },
                component: () => import('../screens/sistema/Configurar.vue')
            },
            {
                path: '/sistema/configuracion/auto_aprobacion_documentos',
                name: 'sistema/configuracion/auto_aprobacion_documentos',
                meta: {
                    title: 'Configuración / Sistema / Aprobacion automatica documentos'
                },
                component: () => import('../screens/usuario/UsuarioSistema.vue')
            },
            {
                path: '/sistema/configurar_vencimiento',
                name: 'sistema/configurar_vencimiento',
                meta: {
                    title: 'Configuración / Configuración vencimientos'
                },
                component: () => import('../screens/sistema/ConfigurarVencimiento.vue')
            },
            {
                path: '/tareas/tareas',
                name: 'tareas/tareas',
                meta: {
                  title: 'Tareas / Historial'
                },
                component: () => import('../screens/tarea/Tareas.vue')
            },
            {
                path: '/tareas/tareas_asignadas_a_mi',
                name: 'tareas/tareas_asignadas_a_mi',
                meta: {
                  title: 'Tareas / Asignadas a mi'
                },
                component: () => import('../screens/tarea/MisTareas.vue')
            },
            {
                path: '/tareas/tareas/agregar',
                name: 'tareas/tareas/agregar',
                meta: {
                  title: 'Tareas / Agregar tarea'
                },
                component: () => import('../screens/tarea/Agregar.vue')
            },
            {
                path: '/tareas/tareas/usuarios/:id_tarea/:nombre_tarea',
                name: 'tareas/tareas/usuarios',
                props: true,
                meta: {
                    title: 'Tareas / Editar usuarios asignados'
                },
                component: () => import('../screens/tarea/EditarUsuarios.vue')
            },
            {
                path: '/tareas/categorias',
                name: 'tareas/categorias',
                meta: {
                  title: 'Configuración / Tareas / Categorias'
                },
                component: () => import('../screens/tarea/categoria/Categorias.vue')
            },{
                path: '/tareas/categorias/agregar',
                name: 'tareas/categorias/agregar',
                meta: {
                  title: 'Configuración / Tareas / Categorias / Agregar'
                },
                component: () => import('../screens/tarea/categoria/Agregar.vue')
            },
            {
                path: '/tareas/categorias/editar/:id_categoria',
                name: 'tareas/categorias/editar',
                meta: {
                  title: 'Configuración / Tareas / Categorias / Editar'
                },
                props: true,
                component: () => import('../screens/tarea/categoria/Editar.vue')
            },
            {
                path: '/tareas/archivo',
                name: 'tareas/archivo',
                meta: {
                  title: 'Tareas / Cargar archivo'
                },
                component: () => import('../screens/tarea/archivo/Archivos.vue')
            },
            {
                path: '/tareas/archivo/cargar',
                name: 'tareas/archivo/cargar',
                meta: {
                  title: 'Tareas / Cargar archivo / Cargar'
                },
                component: () => import('../screens/tarea/archivo/Cargar.vue')
            },
            {
                path: '/vehiculo/tipo/',
                name: 'vehiculo/tipo',
                meta: {
                    title: 'Configuración / Vehiculos / Tipo de vehiculo'
                },
                component: () => import('../screens/vehiculo/tipo/Tipos.vue')
            },
            {
                path: '/vehiculo/tipo/agregar',
                name: 'vehiculo/tipo/agregar',
                meta: {
                    title: 'Configuración / Vehiculos / Tipo de vehiculo / Agregar'
                },
                component: () => import('../screens/vehiculo/tipo/Agregar.vue')
            },
            {
                path: '/vehiculo/tipo/editar/:tipo_vehiculo',
                name: 'vehiculo/tipo/editar',
                props: true,
                meta: {
                    title: 'Configuración / Vehiculos / Tipo de vehiculo / Editar'
                },
                component: () => import('../screens/vehiculo/tipo/Editar.vue')
            },
            {
                path: '/vehiculo/vehiculos',
                name: 'vehiculo/vehiculos',
                meta: {
                    title: 'Vehiculos'
                },
                component: () => import('../screens/vehiculo/Vehiculos.vue')
            },
            {
                path: '/vehiculo/agregar',
                name: 'vehiculo/agregar',
                meta: {
                    title: 'Vehiculos / Agregar '
                },
                component: () => import('../screens/vehiculo/Agregar.vue')
            },
            {
                path: '/vehiculo/editar/:vehiculo',
                name: 'vehiculo/editar',
                props: true,
                meta: {
                    title: 'Vehiculos / Editar '
                },
                component: () => import('../screens/vehiculo/Editar.vue')
            },
            {
                path: '/vehiculo/documento/documentos',
                name: 'vehiculo/documento/documentos',
                meta: {
                    title: 'Vehiculos / Documentos'
                },

                component: () => import('../screens/vehiculo/documento/Documentos.vue')
            },
            {
                path: '/vehiculo/documento/historicos',
                name: 'vehiculo/documento/historicos',
                meta: {
                    title: 'Vehiculos / Documentos historicos'
                },
                component: () => import('../screens/vehiculo/documento/Historico.vue')
            },
            {
                path: '/vehiculo/documento/configurar*',
                name: 'vehiculo/documento/configurar',
                meta: {
                    title: 'Configuración / Documentos vehiculos '
                },
                component: () => import('../screens/vehiculo/documento/Configurar.vue')
            },
            {
                path: '/vehiculo/documento/categoria/agregar/:tipo_vehiculo',
                name: 'vehiculo/documento/categoria/agregar',
                meta: {
                    title: 'Configuración / Documentos vehiculos / Agregar categoria'
                },
                component: () => import('../screens/vehiculo/documento/categoria/AgregarCategoria.vue')
            },
            {
                path: '/vehiculo/documento/categoria/editar/:categoria',
                name: 'vehiculo/documento/categoria/editar',
                meta: {
                    title: 'Configuración / Documentos vehiculos / Editar categoria'
                },
                component: () => import('../screens/vehiculo/documento/categoria/EditarCategoria.vue')
            },
            {
                path: '/vehiculo/documento/tipo_documento/agregar/:categoria',
                name: 'vehiculo/documento/tipo_documento/agregar',
                meta: {
                    title: 'Configuración / Documentos vehiculos / Agregar documento'
                },
                component: () => import('../screens/vehiculo/documento/tipo_documento/AgregarTipoDocumento.vue')
            },
            {
                path: '/vehiculo/documento/tipo_documento/editar/:tipo',
                name: 'vehiculo/documento/tipo_documento/editar',
                meta: {
                    title: 'Configuración / Documentos vehiculos / Editar documento'
                },
                component: () => import('../screens/vehiculo/documento/tipo_documento/EditarTipoDocumento.vue')
            },
            {
                path: '/vehiculo/documento/descarga_masiva',
                name: 'vehiculo/documento/descarga_masiva',
                meta: {
                  title: 'Descarga de documentos / Vehiculos'
                },
                component: () => import('../screens/vehiculo/documento/DescargaMasiva.vue')
            },
            {
                path: '/vehiculo/documento/vencimientos',
                name: 'vehiculo/documento/vencimientos',
                meta: {
                  title: 'Reportes / Vehiculos / Vencimiento documentos'
                },
                component: () => import('../screens/vehiculo/documento/Vencimientos.vue')
            },
            {
                path: '/menu/menu',
                name: 'menu/menu',
                props: true,
                meta: {
                    title: 'Menu / Funcionalidades'
                },
                component: () => import('../screens/menu/ScreenMenu.vue')
            },
            {
                path: '/menu/agregar',
                name: 'menu/agregar',
                props: true,
                meta: {
                    title: 'Menu / Funcionalidad / Agregar'
                },
                component: () => import('../screens/menu/Agregar.vue')
            },
            {
                path: '/menu/editar/:id_funcionalidad',
                name: 'menu/editar',
                props: true,
                meta: {
                    title: 'Menu / Funcionalidad / Editar'
                },
                component: () => import('../screens/menu/Editar.vue')
            },
            {
                path: '/menu/funcionalidad/perfil/:id_funcionalidad',
                name: 'menu/funcionalidad/perfil',
                props: true,
                meta: {
                    title: 'Menu / Funcionalidad / Perfiles'
                },
                component: () => import('../screens/menu/ScreenMenuFuncionalidadPerfil.vue')
            },
            {
                path: '/faena/faenas',
                name: 'faena/faenas',
                meta: {
                  title: 'Faenas'
                },
                component: () => import('../screens/faena/Faenas.vue')
            },
            {
                path: '/faena/agregar',
                name: 'faena/agregar',
                meta: {
                  title: 'Faena / Agregar'
                },
                component: () => import('../screens/faena/Agregar.vue')
            }
            ,
            {
                path: '/faena/editar/:id_faena',
                name: 'faena/editar',
                meta: {
                  title: 'Faena / Editar'
                },
                props: true,
                component: () => import('../screens/faena/Editar.vue')
            },
            {
                path: '/faena/trabajador/:id_faena',
                name: 'faena/trabajador',
                meta: {
                  title: 'Faena / Trabajadores'
                },
                props: true,
                component: () => import('../screens/faena/trabajador/Trabajadores.vue')
            },
            {
                path: '/faena/documento/documentos',
                name: 'faena/documento/documentos',
                meta: {
                  title: 'Faena / Documentos'
                },
                component: () => import('../screens/faena/documento/Documentos.vue')
            },
            {
                path: '/faena/documento/configurar',
                name: 'faena/documento/configurar',
                meta: {
                  title: 'Configuración / Documentos faenas'
                },
                component: () => import('../screens/faena/documento/Configurar.vue')
            },
            {
                path: '/faena/documento/categoria/agregar/',
                name: 'faena/documento/categoria/agregar',
                meta: {
                  title: 'Configuración / Documentos faenas / Categoria / Agregar'
                },
                component: () => import('../screens/faena/documento/AgregarCategoria.vue')
            },
            {
                path: '/faena/documento/categoria/editar/:id_categoria',
                name: 'faena/documento/categoria/editar',
                meta: {
                  title: 'Configuración / Documentos faenas / Categoria / Editar'
                },
                component: () => import('../screens/faena/documento/EditarCategoria.vue')
            },
            {
                path: '/faena/documento/tipo_documento/agregar/:id_categoria',
                name: 'faena/documento/tipo_documento/agregar',
                meta: {
                  title: 'Configuración / Documentos faenas / Tipo de documento / Agregar'
                },
                component: () => import('../screens/faena/documento/AgregarTipoDocumento.vue')
            },
            {
                path: '/faena/documento/tipo_documento/editar/:id_tipo',
                name: 'faena/documento/tipo_documento/editar',
                meta: {
                  title: 'Configuración / Documentos faenas / Tipo de documento / Editar'
                },
                component: () => import('../screens/faena/documento/EditarTipoDocumento.vue')
            },
            {
                path: '/faena/documento/historicos',
                name: 'faena/documento/historicos',
                meta: {
                  title: 'Faena / Documentos historicos'
                },
                component: () => import('../screens/faena/documento/Historico.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../screens/ScreenLogin.vue')
    },
    {
        path: '/activarCliente',
        name: 'activarCliente',
        component: () => import('../screens/ScreenActivarCliente.vue')
    },
    {
        path: '/recuperarPass',
        name: 'recuperarPass',
        component: () => import('../screens/ScreenRecuperarContrasena.vue')
    },
    {
        path: '*', redirect: '/login'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

const DEFAULT_TITLE = 'Pagina sin titulo';

// eslint-disable-next-line no-unused-vars
router.afterEach((to,from) => {
  Vue.nextTick(() => {
    window.dispatchEvent(new CustomEvent('page-title-changed', {
      detail: {
        titulo:to.meta.title || DEFAULT_TITLE,
      }
    }));
  });
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/activarCliente','/recuperarPass'];

  //reviso si usuario tiene acceso a pagina
  if(tieneAcceso(to) == false){
    console.log("Sin acceso");
    console.log(to.name);
    return next(from.path);
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
      return next('/login');
  }

  next();
})

function tieneAcceso(to){
  const accesoLibre = ['/login','/activarCliente','/home', '/recuperarPass'];
  let to_name = "/"+to.name;
  let tiene_acceso = false;

  if(userService.getUser() != null){
    if(accesoLibre.includes(to_name)){
      tiene_acceso = true;
    }

    userService.getUser().menu.forEach(
      function(element) {
        if(to_name == element.href){
          tiene_acceso = true;
        }
      }
    );
    
    return tiene_acceso;
  }
}