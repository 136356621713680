import { utils } from '@/helpers';
import { userService } from './user.services.js';
import { config } from './config';

const tx = {
  'login':0,
  'recuparPassUsuario':1,
  'getUsuario':2,
  'verificarCorreoUniqueId':3,
  'putNuevaPass':4,
  'postUsuario':5,
  'putUsuario':6,
  'putPassUsuario':7,
  'getUsuarios':8,
  'getUsuariosAutoAprob':9,
  'putUsuariosAutoAprob':10,

  'getMenu':500,
  'getMenuFuncionalidades':501,
  'getMenuFuncionalidadPerfiles':502,
  'postMenuFuncionalidad':503,
  'putMenuFuncionalidadPerfil':504,
  'putMenuFuncionalidad':505,

  //trabajador
  'getIndicadoresTrabajador': 2000,
  'getIndicadoresTrabajadorFaena':2001,
  'getTrabajador':2002,
  'getDocumentoTrabajadores':2004,
  'getDocumentosTrabajadorFaena':2006,
  'getDocBitacoraTrabajador':2008,
  'getTrabajadoresFaena':2009,
  'putDocumentoTrabajador':2010,
  'deleteDocumentoTrabajador':2011,
  'getTipoTrabajador':2014,
  'postTrabajador':2015,
  'putTrabajador':2016,
  'getDocumentosHistoricosTrabajador':2017,
  'getConfiguracionDocumentosTrabajador':2018,
  'postCategoriaDocumentoTrabajador':2019,
  'putCategoriaDocumentoTrabajador':2020,
  'postTipoDocumentoTrabajador':2021,
  'putTipoDocumentoTrabajador':2022,
  'getDocumentosDescargaTrabajador':2023,
  'downloadDocumentosTrabajador':2024,
  'getCategoriaDocumentoTrabajador':2025,
  'getTipoDocumentoTrabajador':2026,
  'postTipoTrabajador':2027,
  'putTipoTrabajador':2028,
  'getVencimientoDocumentosTrabajador':2029,
  'getTrabajadoresInFaena':2030,
  'putTrabajadorFaena':2031,
  'getIndicadoresTrabajador2':2033,
  'getIndicadoresTrabajadorFaena2':2034,
  'getCamposTrabajador':2035,
  'postCamposTrabajador':2036,
  'getVencimientoDocumentosTrabajadorFaena':2037,
  'getDocumentosDescargaTrabajadorFaena':2038,
  'getTrabajadorResumenGlobalDocumentos':2039,
  'getTrabajadorResumenDocumentos':2040,
  'getTrabajadorBitacora':2041,
  'putDocumentoTrabajadorFechaVencimiento':2043,
  'deleteTrabajador':2044,

  'getIndicadoresFaena':3000,
  'getFaena':3001,
  'getDocumentosFaena':3002,
  'getDocBitacoraFaena':3006,
  'putDocumentoFaena':3007,
  'deleteDocumentoFaena':3008,
  'getSupervisorFaena':3010,
  'postFaena':3011,
  'putFaena':3012,
  'getDetalleFaenaHtml':3013,
  'getIndicadoresFaena2':3014,
  'getFaenas':3015,
  'getCategoriaDocumentoFaena':3016,
  'getTipoDocumentoFaena':3017,
  'getConfiguracionDocumentosFaena':3018,
  'postCategoriaDocumentoFaena':3019,
  'putCategoriaDocumentoFaena':3020,
  'postTipoDocumentoFaena':3021,
  'putTipoDocumentoFaena':3022,
  'getDocumentosHistoricosFaena':3023,

  //empresa
  'getIndicadoresEmpresa':4000,
  'getEmpresa':4001,
  'getDocumentosEmpresa':4002,
  'getDocBitacoraEmpresa':4006,
  'putDocumentoEmpresa':4007,
  'deleteDocumentoEmpresa':4008,
  'putEmpresa':4010,
  'postEmpresa':4011,
  'getCategoriaDocumentoEmpresa':4012,
  'getTipoDocumentoEmpresa':4013,
  'getDocumentosHistoricosEmpresa':4014,
  'getConfiguracionDocumentosEmpresa':4015,
  'postCategoriaDocumentoEmpresa':4016,
  'putCategoriaDocumentoEmpresa':4017,
  'postTipoDocumentoEmpresa':4018,
  'putTipoDocumentoEmpresa':4019,
  'getDocumentosDescargaEmpresa':4020,
  'downloadDocumentosEmpresa':4021,
  'getVencimientoDocumentosEmpresa':4022,
  'getIndicadoresEmpresa2':4023,
  'getReporteDocumentosEmpresa':4024,
  'getEmpresaBitacora':4026,
  'putDocumentoEmpresaFechaVencimiento':4027,

  'postCliente':5000,
  'putCliente':5001,
  'getCliente':5002,
  'activarCliente':5003,

  //vehiculo
  'postTipoVehiculo':9000,
  'getTipoVehiculos':9001,
  'putTipoVehiculo':9003,
  'postVehiculo':9004,
  'getCategoriaDocumentoVehiculo':9005,
  'postCategoriaDocumentoVehiculo':9006,
  'putCategoriaDocumentoVehiculo':9008,
  'postTipoDocumentoVehiculo':9009,
  'getTipoDocumentoVehiculo':9010,
  'putTipoDocumentoVehiculo':9011,
  'getVehiculos':9012,
  'putVehiculo':9014,
  'getDocumentosVehiculo':9015,
  'getDocBitacoraVehiculo':9017,
  'deleteDocumentoVehiculo':9018,
  'putDocumentoVehiculo':9019,
  'getIndicadoresVehiculo':9022,
  'getMarcaVehiculo':9023,
  'getDocumentosHistoricosVehiculo':9024,
  'getConfiguracionDocumentosVehiculo':9025,
  'getDocumentosDescargaVehiculo':9026,
  'downloadDocumentosVehiculo':9027,
  'getVencimientoDocumentosVehiculo':9028,
  'getIndicadoresVehiculo2':9029,
  'postMarcaVehiculo':9030,
  'getVehiculoResumenDocumentos':9031,
  'putDocumentoVehiculoFechaVencimiento':9032,

  //sucursal
  'getSucursal':10000,
  'postSucursal':10001,
  'putSucursal':10002,
  'getUsuariosSucursal':10003,
  'putUsuarioSucursal':10004,
  'getTrabajadoresSucursal':10005,
  'putTrabajadorSucursal':10006,
  'putTrabajadoresSucursal':10007,
  'getEmpresasSucursal':10008,
  'putEmpresaSucursal':10009,
  'putEmpresasSucursal':10010,
  'getSucursalEmpresasAsociadas':10011,
  'getVehiculosSucursal':10012,
  'putVehiculoSucursal':10013,
  'putVehiculosSucursal':10014,

  //tarea
  'postCategoriaTarea':12000,
  'getCategoriaTarea':12001,
  'putCategoriaTarea':12002,
  'postTarea':12003,
  'getTarea' :12004,
  'getUsuariosTarea':12005,
  'putUsuarioTarea':12006,
  'getBitacoraTarea':12007,
  'putTarea':12008,
  'postArchivoTarea':12009,
  'getArchivoTarea':12010,
  'getIndicadorPorCategoriaTarea':12022,
  'getPendientePorCategoriaTarea':12023,

  //widget
  'getWidgetInicio':13000,
  'postWidgetInicio':13001,
  'getWidgets':13002,

  //sistema
  'getConfiguracionVencimiento':14000,
  'putConfiguracionVencimiento':14001,
}

function fileDownload(url, filename){
  fetch(url).then(function(response) {
    if (!response.ok) {
      throw new Error("HTTP error, status = " + response.status);
    }
    return response.arrayBuffer();
  })
  .then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename) //or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

function requestOptions(codigo, data) {
  let token = (codigo == tx.login || codigo == tx.recuparPassUsuario || codigo == tx.verificarCorreoUniqueId ||codigo == tx.putNuevaPass) ? null : userService.getConfiguracion().t;

  let json = JSON.stringify({
    'c':codigo,
    'd':data, 
    'ic':(codigo == tx.login || codigo == tx.recuparPassUsuario || codigo == tx.verificarCorreoUniqueId ||codigo == tx.putNuevaPass) ? null : userService.getUser().id_cliente,
    'iu':(codigo == tx.login || codigo == tx.recuparPassUsuario || codigo == tx.verificarCorreoUniqueId ||codigo == tx.putNuevaPass) ? null : userService.getUser().id_usuario
  });
  
  return {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CF-Access-Token': `Bearer ${token}`
    },
    body:utils.encrypt(json)
  }
}

function post(codigo, data){
  //por si se necesita enviar el id_cliente
  if(data.id_cliente == null && userService.getUser() != null){
    data.id_cliente = userService.getUser().id_cliente;
  }

  let options = requestOptions(
    codigo,
    data
  );
  
  return fetch(`${config.apiUrl}`, options)
    .then(handleResponse)
    .then(data => {
      return data;
    });
}

function handleResponse(response) {
  let data;
  return response.text().then(text => {
      try {
        data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
      } catch (error){
        if(config.isDev){
          console.log(error);
          return {msg:error,status:'danger'};
        }else{
          return {msg:'Error de conexion',status:'danger'};
        }
      }
      return data;
  });
}

export const api = {
  fileDownload,
  requestOptions,
  tx,
  post
}
