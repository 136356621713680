import { api } from './api';
import { userService } from './user.services.js';

export const azemiService = {
  getMenu
};

function getMenu() {
  return api.post(
    api.tx.getMenu, 
    {
      'id_usuario':userService.getUser().id_usuario,
      'configuracion':userService.getConfiguracion()
    }
  ).then(data => {
    return data;
  });
}