import { api } from './api';

export const sistemaService = {
  getConfiguracionVencimiento,
  putConfiguracionVencimiento
};

function getConfiguracionVencimiento(data) {
  return api.post(
    api.tx.getConfiguracionVencimiento, 
    data
  ).then(data => {
    return data;
  });
}

function putConfiguracionVencimiento(data) {
  return api.post(
    api.tx.putConfiguracionVencimiento, 
    data
  ).then(data => {
    return data;
  });
}